import React from 'react'
import "../style.css"

export default function Footer() {
  return (
    <div className='Footer'>
        <p>Copyright 2022 | Clouded Crew. All Rights Reserved.</p>
    </div>
  )
}
